<template>
	<div class="rounded-xl m-4 rounded-2xl flex justify-center	items-center bg-gray-100"
		style="height: calc(100vh - 8rem)">
		<span v-if="type === 'zeroResults'">There are no results for this search query...</span>
		<span v-if="type === 'zeroChannels'">This bucket does not contain any channels.<br/><br/>Either add a channel, or pick another bucket.</span>
	</div>
</template>

<script>

export default {
  name: 'SearchError',
	props: ['type']
}
</script>

<style lang="scss" scoped>
</style>